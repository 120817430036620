import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Modal,
  message,
  Typography,
  Space,
  List,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Edit, Trash2 } from "lucide-react";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { data } from "jquery";

const { Title } = Typography;
const API_BASE_URL = "https://parnetslink.xyz/api/admin";
const { confirm } = Modal;

const FirebaseGoogleManagement = () => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const encryptedToken = Cookies.get("token");
  const token = encryptedToken
    ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(
        CryptoJS.enc.Utf8
      )
    : null;

  const [firebaseConfigs, setFirebaseConfigs] = useState({});

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchFirebaseConfigs();
  }, []);

  const fetchFirebaseConfigs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getfirebaseconfig`);
      if (response.status == 200) {
        const decodedStr = atob(response.data?.data);
        const responseJson = JSON.parse(decodedStr);
        setFirebaseConfigs(responseJson);
      }
    } catch (error) {
      // console.log(error);
      setFirebaseConfigs({})
      message.error("Failed to fetch Firebase configurations");
    }
  };

  const handleAddOrEdit = async (values) => {
    try {
      await axios.post(
        `${API_BASE_URL}/addfirebaseconfig`,
        values,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success(
        editingItem ? "Updated successfully" : "Added successfully"
      );
      setIsModalVisible(false);
      setEditingItem(null);

      // Fetch updated Firebase configs
      fetchFirebaseConfigs();

      // Reset form fields
      form.resetFields();
    } catch (error) {
      message.error("Operation failed");
    }
  };

  const handleDelete = async (id, type) => {
    try {
      await axios.delete(`${API_BASE_URL}/postdeleteFirese/${id}`,{
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Deleted successfully");
      fetchFirebaseConfigs();
    } catch (error) {
      // console.log(error);
      
      message.error("Failed to delete");
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(id);
      },
      onCancel() {
        // console.log("Deletion cancelled");
      },
    });
  };
  
  const ignoredFields = ["_id", "isActive", "__v"];
  const renderList = (data, type) => {
    const dataSource = Object.entries(data); // Convert object to array of key-value pairs

    return (
      <List
        bordered
        dataSource={dataSource?.filter(([key]) => !ignoredFields.includes(key))}
        renderItem={([key, value]) => (
          <List.Item>
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
              <strong >{key?.toUpperCase()}:</strong>
              <span>{value}</span>
            </Space>
          </List.Item>
        )}
     
        footer={
          <Space>
             {dataSource.length>0?<>
              <Button
              type="primary"
              icon={<Edit />}
              onClick={() => {
                setEditingItem(data);
                setModalType(type);
                form.setFieldsValue(data);
                setIsModalVisible(true);
              }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              danger
              icon={<Trash2 />}
              onClick={() => showDeleteConfirm(data._id)}
            >
              Delete
            </Button>
             </>:null}
           
          </Space>
        }
      />
    );
  };
  const renderFormFields = () => {
    return (
      <>
        <Title level={5}>Firebase Configurations</Title>
        <Form.Item
          name="apiKey"
          label="API Key"
          rules={[{ required: true, message: "Please enter API Key" }]}
        >
          <Input placeholder="API Key" />
        </Form.Item>
        <Form.Item name="authDomain" label="Auth Domain">
          <Input placeholder="Auth Domain" />
        </Form.Item>
        <Form.Item name="projectId" label="Project ID">
          <Input placeholder="Project ID" />
        </Form.Item>
        <Form.Item name="storageBucket" label="Storage Bucket">
          <Input placeholder="Storage Bucket" />
        </Form.Item>
        <Form.Item name="messagingSenderId" label="Messaging Sender ID">
          <Input placeholder="Messaging Sender ID" />
        </Form.Item>
        <Form.Item name="appId" label="App ID">
          <Input placeholder="App ID" />
        </Form.Item>
        <Form.Item name="measurementId" label="Measurement ID">
          <Input placeholder="Measurement ID" />
        </Form.Item>
        <Form.Item name="vapidKey" label="Vapid Key">
          <Input placeholder="Vapid Key" />
        </Form.Item>
        <Title level={5}>Googole Location Configurations</Title>
        <Form.Item
          name="googleApi"
          label="Google API Key"
          rules={[{ required: true, message: "Please enter Google API Key" }]}
        >
          <Input placeholder="Google API Key" />
        </Form.Item>
        <Title level={5}>Backend Configurations</Title>
        <Form.Item
          name="jwtKey"
          label="JWT Key"
          rules={[{ required: true, message: "Please enter JWT Key" }]}
        >
          <Input placeholder="JWT Key" />
        </Form.Item>
        <Form.Item
          name="cryptoKey"
          label="Cryptographic Key"
          rules={[
            { required: true, message: "Please enter Cryptographic Key" },
          ]}
        >
          <Input placeholder="Cryptographic Key" />
        </Form.Item>
      </>
    );
  };

  return (
    <div>
 
      <div className="flex justify-between">
      <Title level={2}>Configuration Management</Title>
      {Object.keys(firebaseConfigs).length==0 ?    <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalType("firebase");
            setEditingItem(null);
            form.resetFields();
            setIsModalVisible(true);
          }}
          style={{ marginBottom: 16, marginTop: 32 }}
        >
          Add Configuration
        </Button>:null}
     
      </div>
      <div>{renderList(firebaseConfigs, "firebaseConfig")}</div>;
      <Modal
        title={
          editingItem
            ? `Edit ${
                modalType === "firebase"
                  ? "Firebase"
                  : modalType === "google"
                  ? "Google API"
                  : "Key"
              }`
            : `Add ${
                modalType === "firebase"
                  ? "Firebase"
                  : modalType === "google"
                  ? "Google API"
                  : "Key"
              }`
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddOrEdit} layout="vertical">
          {renderFormFields()}
          <Button type="primary" htmlType="submit">
            {editingItem ? "Update" : "Add"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default FirebaseGoogleManagement;
