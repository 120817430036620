import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
 const api =process.env.REACT_APP_GOOGLE_API_KEY
const GoogleAutocomplete = () => {
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [distance, setDistance] = useState('');
    const [error, setError] = useState('');
  
    useEffect(() => {
      // Load the Google Maps API script only when the component mounts
      const loadScript = (src, callback) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = callback;
        document.head.appendChild(script);
      };
  
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${api}&libraries=places&callback=initMap`,
        () => {
          window.initMap = () => {
            // You can perform other map-related initialization here, if needed
          };
        }
      );
    }, []);
  
    const calculateDistance = () => {
      if (!origin || !destination) {
        setError('Both origin and destination addresses are required!');
        return;
      }
  
      const directionsService = new window.google.maps.DirectionsService();
  
      const request = {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };
  
      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const route = result.routes[0].legs[0];
          setDistance(route.distance.text);  // The distance in text format (e.g., "10.5 km")
          setError('');
        } else {
          setError('Unable to calculate distance. Please check the addresses.');
          setDistance('');
        }
      });
    };
  
    return (
      <div>
        <h1>Calculate Distance Between Addresses (Driving Mode)</h1>
        <input
          type="text"
          placeholder="Enter Origin Address"
          value={origin}
          onChange={(e) => setOrigin(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter Destination Address"
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
        />
        <button onClick={calculateDistance}>Calculate Distance</button>
  
        {distance && <p>Distance: {distance}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    );
  };

export default GoogleAutocomplete;
