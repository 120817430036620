import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Modal,
  Form,
  message,
  Space,
  Card,
  Typography,
  InputNumber,
  Select,
} from "antd";
import { Search, FileSpreadsheet } from "lucide-react";
import axios from "axios";
import * as XLSX from "xlsx";
import moment from "moment";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import StockHistoryTable from "./StockHistoryTable";

const { Title } = Typography;
const { Option } = Select;

const Stock = () => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const encryptedToken = Cookies.get("token");
  const token = encryptedToken
    ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(
        CryptoJS.enc.Utf8
      )
    : null;

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [categoryMap, setCategoryMap] = useState({});
  const [categoryData, setcategorydata] = useState([]);
  const [subCategoryData, setsubcategorydata] = useState([]);
  const [subCategoryMap, setSubCategoryMap] = useState({});
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [AllStock, setAllStock] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState(null);

  const getcategory = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getCategory",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setcategorydata(res.data?.category);
        const categoryMapping = {};
        res.data?.category.forEach((cat) => {
          categoryMapping[cat._id] = cat.categoryName;
        });
        setCategoryMap(categoryMapping);
      }
    } catch (error) {
      message.error("Failed to fetch categories");
    }
  };

  const getsubcategory = async () => {
    try {
      const res = await axios.get(
        "https://parnetslink.xyz/api/admin/getSubcategory",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setsubcategorydata(
          res.data?.Subcategory.filter((item) => item.status === "Active")
        );
        const subCategoryMapping = {};
        res.data?.Subcategory.filter(
          (item) => item.status === "Active"
        ).forEach((subcat) => {
          subCategoryMapping[subcat._id] = subcat.SubcategoryName;
        });
        setSubCategoryMap(subCategoryMapping);
      }
    } catch (error) {
      message.error("Failed to fetch subcategories");
    }
  };

  useEffect(() => {
    getcategory();
    getsubcategory();
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://parnetslink.xyz/api/admin/Getproductlist",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const filteredData = response.data.ProductList;
      setProducts(filteredData);
      setFilteredProducts(filteredData);
    } catch (error) {
      message.error("Failed to fetch products");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = products.filter((product) =>
      Object.values(product).some(
        (val) =>
          val && val.toString().toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredProducts(filtered);
  };

  const handleStatusToggle = async (product) => {
    try {
      const endpoint =
        product.status === "Active"
          ? "changeActiveStatus"
          : "changeInactiveStatus";
      await axios.get(
        `https://parnetslink.xyz/api/admin/${endpoint}/${product._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success(
        `Product ${
          product.status === "Active" ? "blocked" : "unblocked"
        } successfully`
      );
      fetchProducts();
    } catch (error) {
      message.error("Failed to update product status");
    }
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setAllStock(product?.stock_history || []);
    setIsModalOpen(true);
    setSelectedWeight(null);
    form.resetFields();
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSelectedProduct({});
    setSelectedWeight(null);
  };

  const handleStockUpdate = async (values) => {
    try {
      const { stockName, addedStock } = values;
      
      if (!selectedWeight && (selectedProduct.weight || selectedProduct.Clubweight)) {
        message.error("Please select a weight variant");
        return;
      }

      const payload = {
        productId: selectedProduct._id,
        stockName,
        addedStock: Number(addedStock),
        updateBothTypes: true, // Always update both types
      };

      if (selectedWeight) {
        payload.weightId = selectedWeight._id;
        payload.weightValue = selectedWeight.value;
        payload.weightUnit = selectedWeight.units;
      }

      const response = await axios.put(
        "https://parnetslink.xyz/api/admin/addStockPoduct",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success(response.data.message);
      handleCloseModal();
      fetchProducts();
    } catch (error) {
      console.error(error);
      message.error("Failed to update stock.");
    }
  };

  const exportToExcel = () => {
    const exportData = AllStock.map((ele, i) => ({
      "S.No": i + 1,
      StockID: ele?._id,
      "Stock_Name": ele.stockName,
      "Added_Stock": ele.addedStock,
      "Previous_Stock": ele?.previousStock,
      "New_Stock": ele?.newStock,
      "Weight_Variant": ele?.weightValue ? `${ele.weightValue} ${ele.weightUnit}` : "N/A",
      "Club_Product": ele?.isClubProduct ? "Yes" : "No",
      "Date_Time": moment(ele.date).format('lll')
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stock History");
    XLSX.writeFile(workbook, `${selectedProduct?.productname}-stock-history.xlsx`);
  };

  const getCurrentStock = () => {
    if (!selectedWeight) {
      return selectedProduct?.stock - selectedProduct?.sellproduct;
    }
    
    const variant = selectedProduct.weight.find(w => w._id === selectedWeight._id);
    return variant ? variant.stock : 0;
  };

  const getMinStock = () => {
    if (!selectedWeight) {
      return selectedProduct?.ministock;
    }
    
    const variant = selectedProduct.weight.find(w => w._id === selectedWeight._id);
    return variant ? variant.minStock : selectedProduct?.ministock;
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productname",
      key: "productname",
      sorter: (a, b) => a.productname.localeCompare(b.productname),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => categoryMap[category] || "Unknown Category",
    },
    {
      title: "Subcategory",
      dataIndex: "subcategory",
      key: "subcategory",
      render: (subcategory) =>
        subCategoryMap[subcategory] || "Unknown Subcategory",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      sorter: (a, b) => (a?.stock - a?.sellproduct) - (b?.stock - b?.sellproduct),
      render: (_, record) => {
        const currentStock = record?.stock - record?.sellproduct;
        const isLowStock = record?.ministock > currentStock;
        
        return (
          <Space>
            <span
              onClick={() => handleOpenModal(record)}
              style={{
                color: isLowStock ? "red" : "green",
                display: "flex",
                alignItems: "center",
                gap: "3px",
                cursor: "pointer",
              }}
            >
              <span>{currentStock}</span>
              <span>
                {isLowStock ? <FaArrowAltCircleDown /> : <FaArrowAltCircleUp />}
              </span>
            </span>
          </Space>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Button
          type={record.status === "Active" ? "primary" : "danger"}
          onClick={() => handleStatusToggle(record)}
        >
          {record.status === "Active" ? "Active" : "Inactive"}
        </Button>
      ),
    },
  ];

  return (
    <div className="p-0">
      <Card>
        <div className="mb-6">
          <Title level={3}>Stock Management</Title>
          <div className="d-flex justify-content-between">
            <Input
              placeholder="Search products..."
              prefix={<Search size={16} />}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              className="mb-4"
              allowClear
              style={{ width: "30%" }}
            />
          </div>

          <Table
            columns={columns}
            dataSource={filteredProducts}
            rowKey="_id"
            loading={loading}
            pagination={{
              pageSize: 10,
            }}
            scroll={{ x: "max-content" }}
            components={{
              header: {
                cell: (props) => (
                  <th
                    {...props}
                    style={{
                      ...props.style,
                      background: "linear-gradient(#5C870C,#5C870C,#5C870C)",
                      color: "white",
                    }}
                  />
                ),
              },
            }}
          />
        </div>
      </Card>

      <Modal
        title={`Update Stock for ${selectedProduct?.productname}`}
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
        width={800}
      >
        <div className="d-flex justify-content-between mb-4">
          <div>
            <p>
              <strong>Remaining Stock:</strong> {getCurrentStock()}
            </p>
            <p>
              <strong>Min Stock:</strong> {getMinStock()}
            </p>
          </div>
          <Button icon={<FileSpreadsheet size={16} />} onClick={exportToExcel}>
            Export Excel
          </Button>
        </div>

        {(selectedProduct.weight || selectedProduct.Clubweight) && (
          <div className="mb-4">
            <Form.Item label="Select Weight Variant">
              <Select
                style={{ width: '100%' }}
                placeholder="Select weight variant"
                value={selectedWeight?._id}
                onChange={(value) => {
                  const selected = selectedProduct.weight.find(w => w._id === value);
                  setSelectedWeight(selected);
                }}
              >
                {selectedProduct.weight?.map((weight) => (
                  <Option key={weight._id} value={weight._id}>
                    {weight.value} {weight.units} (Current: {weight.stock})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}

        {AllStock.length > 0 && (
          <div className="mb-4">
            <h4>Stock History</h4>
            <StockHistoryTable 
              stockHistory={AllStock} 
              showWeightVariant={!!(selectedProduct.weight || selectedProduct.Clubweight)}
            />
          </div>
        )}

        <Form form={form} onFinish={handleStockUpdate} layout="vertical">
          <Form.Item
            label="Stock Name"
            name="stockName"
            rules={[
              { required: true, message: "Please input the stock name!" },
            ]}
          >
            <Input placeholder="Enter stock name" />
          </Form.Item>

          <Form.Item
            label="Added Stock"
            name="addedStock"
            rules={[
              { required: true, message: "Please input the stock quantity!" },
              {
                validator: (_, value) => {
                  if (value <= 0) {
                    return Promise.reject('Stock must be greater than 0');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              placeholder="Enter stock quantity"
              style={{ width: "100%" }}
              min={1}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Stock
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Stock;