
import { initializeApp } from "firebase/app";
import { getMessaging ,getToken} from "firebase/messaging";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId:process.env.REACT_APP_PROJECT_ID,
    storageBucket:process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
function requestPermission() {
  // console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // console.log("Notification permission granted.");
      getToken(messaging, {
        vapidKey:  process.env.REACT_APP_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("FCM Token: ", currentToken);
         
          } else {
            // console.log("No registration token available. Request permission to generate one.");
          }
        })
        .catch((err) => {
          // console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      // console.log("Do not have permission!");
    }
  });
}

requestPermission();
export { app, messaging };
