import React, { useState } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY; // Replace with your Google Maps API key

const AutocompleteComponent = () => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [formData, setFormData] = useState({
    landmark: "",
    address1: "",
    address2: "",
    postcode: "",
    lat: "",
    lng: "",
  });

  const handlePlaceSelect = () => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();
    if (!place.geometry || !place.address_components) return;

    const addressComponents = place.address_components;

    let landmark = place?.formatted_address;
    let address1 = place.name;
    let address2 = "";
    let postcode = "";
    let lat = place.geometry.location.lat();
    let lng = place.geometry.location.lng();

    // Extract address components
    addressComponents.forEach((component) => {
      const types = component.types;
    
   
      if (types.includes("route")) {
        address1 += component.long_name;
      }
      if (types.includes("locality")) {
        address2 = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        address2 += ", " + component.short_name;
      }
      if (types.includes("postal_code")) {
        postcode = component.long_name;
      }
    });

    // Update state with extracted data
    setFormData({ landmark, address1, address2, postcode, lat, lng });
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={["places"]}>
      <div>
        <h2>Google Address Autocomplete</h2>
        <Autocomplete onLoad={setAutocomplete} onPlaceChanged={handlePlaceSelect}>
          <input type="text" placeholder="Enter address" style={{ width: "100%", padding: "10px" }} />
        </Autocomplete>

        <h3>Extracted Address Details:</h3>
        <p><strong>Landmark:</strong> {formData.landmark}</p>
        <p><strong>Address 1:</strong> {formData.address1}</p>
        <p><strong>Address 2:</strong> {formData.address2}</p>
        <p><strong>Postcode:</strong> {formData.postcode}</p>
        <p><strong>Latitude:</strong> {formData.lat}</p>
        <p><strong>Longitude:</strong> {formData.lng}</p>
      </div>
    </LoadScript>
  );
};

export default AutocompleteComponent;
