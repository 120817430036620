import { message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Lottie from 'lottie-react';
import feedback from './../../../assets/feedback.json';
function FeedbackForm() {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const encryptedToken = Cookies.get('token');
  const token = encryptedToken ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(CryptoJS.enc.Utf8) : null;
  const encryptedUser = Cookies.get('user');
  const encryptedClubUser = Cookies.get("clubuser");

  const user = encryptedUser ? JSON.parse(CryptoJS.AES.decrypt(encryptedUser, encryptionKey).toString(CryptoJS.enc.Utf8)) : encryptedClubUser ? JSON.parse(
    CryptoJS.AES.decrypt(encryptedClubUser, encryptionKey).toString(
      CryptoJS.enc.Utf8
    )
  ) : null

  const [formData, setFormData] = useState({
    name: user?.name,
    email: user.email,
    phoneNumber: user?.mobile,
    message: '',

  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [shub, setShow] = useState(true);
  const Feedback = async (e) => {

    e.preventDefault();
    if (!user) {
      return toast.info("No user found. Please login to provide feedback."
      );
    }
    if (!formData.message) return toast.info("Please enter your feedback message");
    try {
      const config = {
        url: "/admin/feedback",
        baseURL: "https://parnetslink.xyz/api",
        method: "post",
        headers: { "Content-Type": "application/json" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {

          email: formData.email,

          name: formData.name,
          mobile: formData.phoneNumber,
          message: formData.message,

        },
      };

      const res = await axios(config);
      if (res.status === 200) {

        toast.info("Successfully Posted"
        );
        setShow(false);
        setTimeout(() => {
          window.location.assign("/");
        }, 3000);

      }
    } catch (error) {
      console.error("Update failed", error);

    }
  };


  return (
    <>
      {shub ? (<form onSubmit={Feedback} className="feedback-form">
        <ToastContainer />
        <h2 className="text-2xl md:text-4xl lg:text-4xl font-bold text-center quicksand mt-1 text-[#f2281c]">Feedback</h2>
        <div className="flex justify-center items-center m-0 p-0 mb-4">
          <span className="w-[15%] h-1 gradient1 rounded-md m-0 p-0"></span>
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="We take each customer feedback seriously & would love to hear from you. Please do spare a moment for us to improve our produce range, quality, service or any other aspect"
          />
        </div>


        <div className='flex justify-center items-center'><button type="submit" className="py-2 px-3 bg-[#5C870C] text-white w-[30%] rounded-md" >Submit</button></div>
      </form>) : (
        <div className='flex justify-center items-center mx-auto'>
          <div>
            <Lottie animationData={feedback} className='size-80' />
            <h1 className='text-3xl font-bold text-center mt-0'>Thank you</h1>
          </div>

        </div>
      )}


    </>
  );
}

export default FeedbackForm;