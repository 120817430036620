export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
          // console.log('SW registered: ', registration);
        })
        .catch(registrationError => {
          // console.log('SW registration failed: ', registrationError);
        });
    });
  }
}