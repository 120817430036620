import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Icons for success and failure
import "./payment.css"; // External CSS for better readability
import axios from "axios";
import { clearCart } from "../redux/CartSlice";
import { clearCart1 } from "../redux/CartSlice1";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  // Extract query parameters
  const transactionId = searchParams.get("transactionId");
  const userId = searchParams.get("userID");

  // State for payment status and details
  const [paymentStatus, setPaymentStatus] = useState(null); // e.g., "CR" or "FAILED"
  const [paymentDetails, setPaymentDetails] = useState(null); // Store the full payment data

  const handleHomeClick = () => {
    navigate("/");
  };

  const checkPaymentStatus = async () => {
    try {
      // Construct API endpoint
      const url = `https://parnetslink.xyz/api/user/checkpaymentstatus/${transactionId}/${userId}`;

      // Make the GET request
      const response = await axios.get(url);

      if (response.status == 200) {
        const paymentData = response.data.success;
        setPaymentDetails(paymentData);

        if (paymentData.status === "COMPLETED") {
          setPaymentStatus("COMPLETED");
          if (paymentData?.orderType !== "scheduled") {
            dispatch(clearCart());
          } else if(paymentData?.orderType){
            dispatch(clearCart1());
          }
          setTimeout(() => {
            if(paymentData?.redirecturl=="/login"){
              Cookies.remove("user");
              Cookies.remove("token")
              window.location.assign(`${paymentData?.redirecturl}`);
            }else{
              window.location.assign(`${paymentData?.redirecturl}?userID=` + userId);
            }
           
          }, 1000);
        } else {
            // alert("Faild")
          setPaymentStatus("FAILED");
          setTimeout(() => {
           
            window.location.assign(`${paymentData?.callBack}`)
          }, 1000);
        }
      } else {
        // Handle unexpected response
        setTimeout(() => {
          navigate("/");
        }, 1000);
        toast.error("Payment Verification Failed!");
      }
    } catch (error) {
      // Handle API errors
      setTimeout(() => {
        navigate("/");
      }, 1000);
      console.error("Error checking payment status:", error);

      toast.error("Unable to verify payment status. Please try again!");
    }
  };

  useEffect(() => {
    // Call payment status check when component mounts
    if (transactionId && userId) {
      checkPaymentStatus();
    } else {
      toast.error("Invalid Payment Details");
      navigate("/");
    }
  }, [transactionId, userId, navigate]);

  return (
    <div className="payment-success-container">
      <ToastContainer />
      {paymentStatus === "COMPLETED" ? (
        <>
          <FaCheckCircle className="payment-success-icon" />
          <h1 className="payment-success-title">Payment Successful!</h1>
          <p className="payment-success-message">
            Thank you for your payment,{" "}
            <strong>{paymentDetails?.username}</strong>.
          </p>
          <p className="payment-success-message">
            Amount: ₹{paymentDetails?.amount}
          </p>
          <button onClick={handleHomeClick} className="payment-success-button">
            Go To Home
          </button>
        </>
      ) : paymentStatus === "FAILED" ? (
        <>
          <FaTimesCircle className="payment-failed-icon" />
          <h1 className="payment-failed-title">Payment Failed</h1>
          <p className="payment-failed-message">
            We could not process your payment. Please try again.
          </p>
          <button
            onClick={() => navigate("/")}
            className="payment-success-button"
          >
            Retry Payment
          </button>
        </>
      ) : (
        <p>Loading payment status...</p>
      )}
    </div>
  );
};

export default PaymentSuccess;
