import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import cartReducer from './CartSlice'; 
import cartReducer1 from './CartSlice1'; 

// Encryption key, keep it safe and secure
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

// Persist configuration with encryption for `cart`
const persistConfigCart = {
  key: 'cart',
  storage,
  transforms: [
    encryptTransform({
      secretKey: encryptionKey,
      onError: function (error) {
        console.error("Encryption error for cart:", error);
      },
    }),
  ],
};

// Persist configuration with encryption for `cart1`
const persistConfigCart1 = {
  key: 'cart1',
  storage,
  transforms: [
    encryptTransform({
      secretKey: encryptionKey,
      onError: function (error) {
        console.error("Encryption error for cart1:", error);
      },
    }),
  ],
};

const persistedCartReducer = persistReducer(persistConfigCart, cartReducer);
const persistedCartReducer1 = persistReducer(persistConfigCart1, cartReducer1);

const rootReducer = combineReducers({
  cart: persistedCartReducer,
  cart1: persistedCartReducer1,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
