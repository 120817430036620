import React, { useState } from "react";
import "./DeliveryLocationModal.css"; 

function DeliveryLocationModal({ onClose }) {
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);

  return (
    <div className="modal-overlay1">
      <div className="modal-content1">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2><b>Set Delivery Location</b></h2>

        <div className="logo-container">
          <img
            src="../logo.svg"
            alt="Organics"
            style={{ width: "200px", height: "200px" }}
          />
         
        </div>

        <p className="text-bold"><b>Select Your Delivery Location</b></p>

        <button
          className={`location-button ${useCurrentLocation ? "active" : ""}`}
          onClick={() => setUseCurrentLocation(true)}
        >
          <span className="location-icon"><i className="fa fa-location pl-1"></i></span> Use my current location
        </button>

        <div className="divider">OR</div>

        <input
          type="text"
          className="location-input"
          placeholder="Enter your location"
          defaultValue="Bangalore, Karnataka, India"
        />
      </div>
    </div>
  );
}

export default DeliveryLocationModal;
