import React from 'react';
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import axios from 'axios';
import { toast } from "react-toastify";

const PaymentModal = ({ isOpen, onClose, onConfirmPayment }) => {
  if (!isOpen) return null;
  const encryptedToken = Cookies.get("token");
  const encryptedUser = Cookies.get("user");
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  if (!encryptedToken || !encryptedUser || !encryptionKey) {
    console.error("Missing encrypted data or encryption key");
  }

  const token = encryptedToken
    ? CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(
        CryptoJS.enc.Utf8
      )
    : null;

      const user = encryptedUser
        ? JSON.parse(
            CryptoJS.AES.decrypt(encryptedUser, encryptionKey).toString(
              CryptoJS.enc.Utf8
            )
          )
        : null;

        const handleCompletePayment = async () => {
          try {
            const clubRegisteredDate = new Date().toISOString();
            const config = {
              url: "/user/changeClubStatus/" + user._id,
              method: "post",
              baseURL: "https://parnetslink.xyz/api",
              hearder: { "content-type": "application/json" },
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                clubRegisteredDate,
              },
            };
      
            const response = await axios.post(
              `https://parnetslink.xyz/api/user/addPaymentPhone`,
              {
                userId: user?._id,
                username: user?.name,
                Mobile: user?.mobile,
                orderId: "CLUBMember" + Math.random(0, 10),
                amount: 200,
                config: JSON.stringify(config),
                redirecturl: "/login",
                callBack: "/",
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.status == 200) {
              return window.location.assign(response.data?.url?.url);
            }
          } catch (error) {
            toast.error("Unable to complete registration. Please try again.");
          }
        };


  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg shadow-xl w-full max-w-sm mx-4 relative"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <div className="flex items-center w-full justify-center">
            <span className="fas fa-credit-card mr-2"></span>
            <h2 className="text-xl font-semibold">Complete Payment</h2>
          </div>
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-6">
          <div className="flex flex-col items-center">
            <div className="bg-gray-50 p-4 rounded-lg w-full mb-6">
              <p className="text-center text-gray-600">Please make a payment of 200 INR to complete your registration.</p>
              <p className="text-center text-3xl font-bold text-gray-800">₹200</p>
            </div>
            
            <div className="w-full space-y-4">
              <button
                onClick={handleCompletePayment}
                className="w-full bg-[#5c870c] text-white py-3 rounded-lg hover:bg-opacity-90 transition-colors font-medium flex items-center justify-center"
              >
                <span className="fas fa-lock mr-2"></span>
                Pay Securely Now
              </button>
              
              <div className="flex items-center justify-center space-x-2 text-gray-500">
                <span className="fas fa-shield-alt"></span>
                <p className="text-xs mt-2">256-bit SSL Secure Payment</p>
              </div>

             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;